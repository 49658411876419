import React from "react"
//
import { SiteDataContext, LocationContext } from "../layout"
import MainFooter from "../../storybook/components/footers/MainFooter"
import { buildLinks, getUrlHostname } from "../../utils/navigation"
import RichText from "../rich-text"

export const Footer = () => {
  const site = React.useContext(SiteDataContext)
  const location = React.useContext(LocationContext)

  const {
    nome,
    footerNavegacaoPrincipal,
    footerNavegacaoLegal,
    footerRedesSociais,
    footerCopyright,
    footerBlocoOndeEstamos
  } = site

  const links = buildLinks(footerNavegacaoPrincipal, location?.pathname, site)
  const legalLinks = buildLinks(footerNavegacaoLegal, location?.pathname, site)
  const socialLinks = footerRedesSociais?.map((link) => {
    return {
      id: link.id,
      title: link.titulo,
      href: link.paginaExterna,
      icon: getUrlHostname(link.paginaExterna)
    }
  })

  return (
    <MainFooter
      links={links}
      legalLinks={legalLinks}
      socialLinks={socialLinks}
      siteName={nome}
      copyright={footerCopyright}
      contactsText={<RichText data={footerBlocoOndeEstamos} />}
      titleNavigation="Navegação"
      titleLegal="Legal"
      titleLocation="Onde Estamos?"
    />
  )
}

export default Footer
