import React, { useContext } from "react"
import { Link } from "gatsby"
//
import { SiteDataContext, LocationContext, PreviewContext } from "../components/layout"
import { slugify } from "../storybook/utils"

const SITE_URL = process.env.SITE_URL || ""

export const getSlug = (content, contentfulSite) => {
  if (!content) {
    return null
  }

  const { id, slug } = content
  const homepage = contentfulSite?.homepage
  const formacao = contentfulSite?.paginaFormacao

  if (!id || !slug) {
    return null
  }

  if (homepage?.id === id) {
    return "/"
  }

  if (formacao?.id === id && contentfulSite?.paginaFormacao?.slug) {
    return `/${contentfulSite?.paginaFormacao.slug}`
  }

  return `/${slug}`
}

export const getNavigation = (navigation, contentfulSite) => {
  const {
    id,
    titulo,
    tituloMenus,
    slug,
    contentfulId,
    paginaExterna,
    paginaInterna,
    textoAccessivel,
    __typename: contentType
  } = navigation

  let internalSlug = `${slug}`

  if (slug && contentType === "ContentfulFormacaoCategoria") {
    internalSlug = `${contentfulSite?.paginaFormacao?.slug}/${slug}`
  }

  return {
    id,
    titulo: tituloMenus || titulo,
    textoAccessivel,
    contentfulId: paginaInterna?.contentfulId || contentfulId,
    to: paginaExterna || (paginaInterna ? getSlug(paginaInterna, contentfulSite) : `/${internalSlug}`)
  }
}

export const NavigationLink = ({ navigation, ...rest }) => {
  const siteData = useContext(SiteDataContext)

  if (!navigation?.id && !navigation?.sys?.id) {
    return null
  }

  if (navigation?.sys?.id) {
    navigation.id = navigation.sys.id
  }

  const navigationValue = getNavigation(navigation, siteData)

  return <NavLink {...navigationValue} {...rest} />
}

export const NavLink = ({ to, titulo, textoAccessivel, children, contentfulId, active = "false", ...rest }) => {
  const preview = useContext(PreviewContext)

  if (!to) {
    return null
  }

  const props = {}

  let path = to?.startsWith(SITE_URL) ? to.replace(SITE_URL, "") : to

  if (textoAccessivel) {
    props["aria-label"] = textoAccessivel
  }

  if (path.startsWith("http")) {
    props.target = "_blank"
    props.rel = "noreferrer nofollow"

    return (
      <a href={path} {...props} {...rest}>
        {titulo || children}
      </a>
    )
  }

  if (path.startsWith("#")) {
    return <AnchorLink slug={path.replace(/^#/, "")} titulo={titulo} {...props} />
  }

  if (preview && contentfulId) {
    path = `/preview/?slug=${path.replace("/", "")}`
  }

  const { id, ...other } = rest

  if (active === true) {
    props.onClick = (e) => {
      e.preventDefault()
      return false
    }
  }

  return (
    <Link activeClassName={`${active && "active"}`} to={path} {...props} {...other}>
      {titulo || children}
    </Link>
  )
}

export const AnchorLink = ({ slug, text, titulo, textoAccessivel, ...rest }) => {
  const [section, setSection] = React.useState(null)
  const location = useContext(LocationContext)

  React.useEffect(() => {
    const el = document.querySelector(`#${slug}`)

    if (el) {
      setSection(el)
    }
  }, [slug])

  if (!section) {
    return <>{text || titulo}</>
  }

  const props = {}

  if (textoAccessivel) {
    props["aria-label"] = textoAccessivel
  }

  return (
    <Link
      onClick={(e) => {
        e.preventDefault()
        section.scrollIntoView({ behavior: "smooth", block: "start" })
        window.history.pushState("", document.title, `${location.pathname}#${slug}`)
      }}
      to={`${location.pathname}#${slug}`}
      {...props}
      {...rest}
    >
      <span>{text || titulo}</span>
    </Link>
  )
}

export const isNavigationActive = (slug, location) => {
  return location.pathname === slug || location.pathname.startsWith(slug)
}

export const getAnchorLink = (text) => {
  const slug = slugify(text)
  const value = text.startsWith("#") ? text.replace(/^#/, "") : text

  return <AnchorLink key={slug} slug={slug} text={value} />
}

export const buildLinks = (links, active, contentfulSite) => {
  return links?.map((link) => {
    const navigation = getNavigation(link, contentfulSite)

    const isActive = active && (active === navigation.to || active.startsWith(navigation.to))

    return <NavLink active={isActive} key={`nav-${link.id}`} {...navigation} />
  })
}

/* eslint-disable no-useless-escape */
export const getUrlHostname = (url) => {
  const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)
  return matches && matches[1]
}
/* eslint-enable no-useless-escape */
