import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import { Link } from "gatsby"
//
import { SiteDataContext } from "../layout"
import { getSlug, getAnchorLink, AnchorLink } from "../../utils/navigation"
import { ImageFluid, createImageFluid } from "../../utils/image"
import BlockListItems from "../blocks/blocks-list-items/blocks-list-items"

const inlineHyperlink = (hyperlink) => {
  const { uri } = hyperlink.data
  const { value } = hyperlink?.content[0]

  if (!uri || !value) {
    return null
  }

  if (uri.startsWith("#")) {
    return <AnchorLink slug={uri.replace(/^#/, "")} text={value} />
  }

  return (
    <a href={uri} target="_blank" rel="noreferrer nofollow">
      {value}
    </a>
  )
}

const entryHyperlink = (hyperlink, site) => {
  const { value } = hyperlink?.content[0]
  const { data } = hyperlink

  const slug = data?.target?.fields?.slug["pt"]
  const type = data?.target?.sys?.contentType?.sys?.id
  const id = data?.target?.sys?.contentful_id

  if (!value || !slug || !type || !id) {
    return null
  }

  const content = {
    id,
    slug
  }

  const to = getSlug(content, site)

  if (!to) {
    return <span>{value}</span>
  }

  return <Link to={to}>{value}</Link>
}

const inlineEntryAsset = (node) => {
  const { sys, fields } = node.data.target

  if (!sys || !fields) {
    return null
  }

  const asset = fields?.file["pt"]

  if (!asset) {
    return null
  }

  const image = createImageFluid({ url: asset.url })

  return (
    <div className="image-container">
      <ImageFluid image={image} />
    </div>
  )
}

const inlineEntryBlock = (node) => {
  const target = node?.data?.target

  if (!target || !target?.__typename) {
    return null
  }

  if (target?.__typename === "ContentfulBlocosListaDeItems") {
    return <BlockListItems data={target} isBlockPage={false} />
  }

  return null
}

const inlineText = (text) => {
  return /^\s+$/.test(text) ? "" : text
}

const renderText = (text) =>
  inlineText(text)
    .split("\n")
    .reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br />, textSegment]
    }, [])
    .map((child) => {
      if (child.type === "br") {
        return React.cloneElement(child, { key: `${Math.random()}` })
      }
      if (child && typeof child === "string" && child.length) {
        const matches = child.match(/\[ANCHOR:(.*)\]/)
        if (matches && matches[1]) {
          return getAnchorLink(matches[1])
        }
      }

      return child
    })

const RichText = ({ data }) => {
  const site = React.useContext(SiteDataContext)

  if (!data?.raw && !data?.json) {
    return null
  }

  if (data?.json) {
    data.raw = JSON.stringify(data.json)
  }

  const richTextOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => inlineHyperlink(node),
      [INLINES.ENTRY_HYPERLINK]: (node) => entryHyperlink(node, site),
      [BLOCKS.EMBEDDED_ASSET]: (node) => inlineEntryAsset(node),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => inlineEntryBlock(node)
    },
    renderText: (text) => renderText(text)
  }

  return <div>{renderRichText(data, richTextOptions)}</div>
}

export default RichText
