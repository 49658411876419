import React from "react"
import tw, { styled } from "twin.macro"
//
import { SectionHeading } from "../misc/Headings"
import { multilineText } from "../../utils"
import { ContentWithPaddingXl } from "../misc/Layouts"

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover bg-gradient-to-tr from-primary-300 to-primary-500`}
`

const HeadingRow = tw.div`flex`
const Heading = tw(SectionHeading)`text-gray-900 mb-1`
const InnerContainer = tw(ContentWithPaddingXl)`pb-2 pt-20 lg:pb-10 lg:pt-24`
const Text = tw.div`text-xl text-gray-800`

export const HeroTitleOnly = ({ heading, text = null }) => {
  return (
    <Container>
      <InnerContainer>
        <HeadingRow>
          <Heading>{multilineText(heading)}</Heading>
        </HeadingRow>
        <HeadingRow>{text ? <Text>{text}</Text> : null}</HeadingRow>
      </InnerContainer>
    </Container>
  )
}

export default HeroTitleOnly
