import React, { useLayoutEffect } from "react"
import tw, { styled } from "twin.macro"
import { Menu as MenuIcon, X as CloseIcon } from "react-feather"
import onClickOutside from "react-onclickoutside"
//
import { SearchIcon, CloseIcon as SearchCloseIcon } from "../../images/Icons"

const Header = styled.header`
  &.sticky {
    position: fixed;
    ${tw`bg-white transition duration-300 shadow-sm`}
    a {
      ${tw`text-primary-500 hocus:text-gray-700 hover:border-primary-700`}
      &.active {
        ${tw`border-primary-500 hover:border-primary-700 hover:text-primary-700`}
      }
    }
  }
`

const InnerHeader = tw.div`flex justify-between items-center max-w-screen-xl mx-auto pl-2 pt-2`

export const NavLinks = styled.div`
  > ul {
    display: flex;
    ${tw.div`inline-block`};

    > li {
      position: relative;
      ${tw`
    text-lg my-2 lg:text-base lg:mx-2 lg:my-0 uppercase
    font-semibold tracking-wide transition duration-300
    pt-1 pb-1 border-b-2 border-transparent hocus:text-gray-100
    `};
      &.active {
        ${tw`border-gray-100`}
      }
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        ${tw`bg-gray-100`};
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.2s ease-in-out 0s;
      }
      &:hover::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }
`

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:ring
  border-b-0
`

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`

const LogoContainer = styled.div`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  ${tw.a`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`};
  img {
    ${tw`w-10 mr-3`}
  }
`

const MobileLogoContainer = styled.div`
  z-index: 99;
  width: 100%;
  img {
    width: 100%;
  }
  a {
    padding: 0.5rem 0px;
    display: block;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  padding: 5px;
  color: white;
`

const NavigationContainer = styled.div`
  height: 48px;
  ${tw`flex items-center`}
`

const SearchButton = styled.button`
  width: 48px;
  height: 48px;
  ${tw`ml-0 md:ml-4`}
`

export const MobileNavLinksContainer = tw.nav`flex flex-1 justify-between`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300 px-3
`
export const MobileNavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300 p-3
`

export const MobileNavLinks = styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-0 my-0 p-4 text-gray-900 bg-primary-500 opacity-95`}
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  ${NavLinks} {
    ${tw`flex flex-col p-4 mt-16 text-white`}
    .active {
      ${tw`text-primary-200 border-none`}
    }
    a {
      ${tw`text-2xl font-semibold`}
    }
  }
`

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`

function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden"
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, []) // Empty array ensures effect is only run on mount and unmount
}

const Modal = ({ children }) => {
  useLockBodyScroll()

  return <>{children}</>
}

export const LightHeader = ({
  roundedHeaderButton = false,
  logoLink,
  links = [],
  mobileLinks = [],
  className,
  collapseBreakpointClass = "lg",
  sticky = false,
  searchActive = false,
  handleSearch = () => {}
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */

  const [showNavLinks, setShowNavLinks] = React.useState(false)
  const toggle = () => setShowNavLinks(!showNavLinks)
  LightHeader.handleClickOutside = () => setShowNavLinks(false)

  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass]

  const headerClass = `${className || "header-light"} ${sticky ? "sticky" : "unsticky"}`

  return (
    <Header className={headerClass}>
      <InnerHeader>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          <LogoContainer>{logoLink}</LogoContainer>
          <NavigationContainer>
            <NavLinks key="main-navigation-links">{links}</NavLinks>
            <SearchButton onClick={handleSearch} aria-label={searchActive ? "fechar pesquisa" : "abrir pesquisa"}>
              {searchActive ? (
                <SearchCloseIcon tw="fill-current text-white" />
              ) : (
                <SearchIcon css={[sticky ? tw`fill-current text-primary-500` : tw`fill-current text-white`]} />
              )}
            </SearchButton>
          </NavigationContainer>
        </DesktopNavLinks>

        {showNavLinks ? (
          <Modal>
            <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
              <MobileLogoContainer>{logoLink}</MobileLogoContainer>
              <MobileNavLinks css={collapseBreakpointCss.mobileNavLinks}>
                <NavLinks key={12}>{mobileLinks}</NavLinks>
              </MobileNavLinks>
              <MobileNavToggle
                onClick={toggle}
                className={showNavLinks ? "open" : "closed"}
                aria-label={showNavLinks ? "fechar menu" : "abrir menu"}
              >
                <StyledCloseIcon tw="w-12 h-12" />
              </MobileNavToggle>
            </MobileNavLinksContainer>
          </Modal>
        ) : (
          <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
            <MobileLogoContainer>{logoLink}</MobileLogoContainer>

            <SearchButton onClick={handleSearch} aria-label={searchActive ? "fechar pesquisa" : "abrir pesquisa"}>
              {searchActive ? (
                <SearchCloseIcon tw="fill-current text-white" />
              ) : (
                <SearchIcon css={[sticky ? tw`fill-current text-primary-500` : tw`fill-current text-white`]} />
              )}
            </SearchButton>

            <MobileNavToggle
              onClick={toggle}
              className={showNavLinks ? "open" : "closed"}
              aria-label={showNavLinks ? "fechar menu" : "abrir menu"}
            >
              <MenuIcon tw="w-6 h-6" />
            </MobileNavToggle>
          </MobileNavLinksContainer>
        )}
      </InnerHeader>
    </Header>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => LightHeader.handleClickOutside
}

export default onClickOutside(LightHeader, clickOutsideConfig)
// export default LightHeader

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
}
