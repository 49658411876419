import React from "react"
import tw, { styled } from "twin.macro"
//
import { SectionHeading } from "../misc/Headings"
import { SectionBlockRichText, SectionBlockListRichText } from "../misc/Typography.js"
import { Container, ContentWithPaddingXl } from "../misc/Layouts"
import { slugify, multilineText } from "../../utils"
import Media from "../../utils/media"

const Heading = tw(SectionHeading)`w-full`
const Description = tw(SectionBlockRichText)`w-full text-center max-w-2xl mx-auto pt-8`

const Column = tw.div`flex flex-col items-center`
const HeaderContent = tw.div``

const RowsContainer = tw.dl`mt-12 max-w-full md:max-w-4xl relative break-words`
const Row = tw.div`mt-10 px-4 md:px-8 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gradient-to-t from-gray-200 to-gray-100 hover:from-gray-300 hover:to-gray-200 transition duration-300`
const Title = tw.dt`flex justify-between items-center`
const TitleText = tw.h3`text-lg lg:text-xl font-semibold py-2`
const DescriptionRow = tw(SectionBlockListRichText)`w-full text-lg leading-relaxed`
const StyledDescription = styled(DescriptionRow)`
  ul { ${tw`list-none`} }
`
 
const StyledImage = styled.div`
  img {
    ${Media({
      maxWidth: ["280px", "490px", "100%", "100%"]
    })}
  }
`

export const InfoListBlock = ({ heading, description, cards = [] }) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            <Heading>{multilineText(heading)}</Heading>
            {description && <Description>{multilineText(description)}</Description>}
          </HeaderContent>
          <RowsContainer>
            {cards.map((row, idx) => (
              <Row id={slugify(row?.title)} key={`row-${row.id || idx}`} className="group">
                <Title>
                  <TitleText>{row?.title}</TitleText>
                </Title>
                <StyledDescription>
                  {row?.description}
                  {row?.image && <StyledImage className="image">{row?.image}</StyledImage>}
                </StyledDescription>
              </Row>
            ))}
          </RowsContainer>
        </Column>
      </ContentWithPaddingXl>
    </Container>
  )
}

export default InfoListBlock
