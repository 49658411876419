import React from "react"
import tw, { styled } from "twin.macro"
//
import { Subheading as SubheadingBase } from "../misc/Headings"
import { Container, ContentWithPadding } from "../misc/Layouts"
import { multilineText } from "../../utils"

const Heading = tw(SubheadingBase)`w-auto text-left text-secondary-500`

const Column = tw.div`flex flex-col`

const RowsContainer = tw.div`mt-4 max-w-4xl relative`
const Row = tw.div`mr-4 mt-4 px-4 py-2 rounded-lg text-gray-800 border-gray-100 bg-gradient-to-t from-gray-200 to-gray-100 px-4 py-2`
const Title = styled.div`
  ${tw`flex flex-row`}
  a {
    ${tw`text-secondary-500 hover:text-secondary-800`}
  }
`
const SymbolSpan = tw.span`inline-block pr-2 text-primary-500`

export const ListItemsBlock = ({ heading, type = "List", symbol = null, items = [], alignCenter = true }) => (
  <Container>
    <ContentWithPadding>
      <Column>
        <Heading css={[alignCenter && tw`text-center items-center`]}>{multilineText(heading)}</Heading>
        <RowsContainer
          css={[
            alignCenter && tw`items-center`,
            type === "List" && tw`flex-col`,
            type === "Grid" && tw`flex flex-wrap`
          ]}
        >
          {items.map((item) => (
            <Row
              key={item.id}
              className="group"
              css={[type === "Grid" && tw`w-auto sm:flex-row items-center sm:items-start text-left`]}
            >
              <Title>
                {symbol ? <SymbolSpan>{symbol}</SymbolSpan> : null}
                {item?.value}
              </Title>
            </Row>
          ))}
        </RowsContainer>
      </Column>
    </ContentWithPadding>
  </Container>
)

export default ListItemsBlock
