import React from "react"
import tw, { styled } from "twin.macro"
//
import { FacebookIcon, TwitterIcon } from "../../images/Icons"

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`
const FiveColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`

const Column = tw.div`px-4 sm:px-0 sm:w-1/3 md:w-auto mt-12`
const DesktopColumn = tw.div`md:px-4 sm:px-0 sm:w-auto md:w-auto md:mt-12`

const ColumnHeading = tw.p`uppercase font-bold`

const LinkList = tw.ul`mt-6 text-sm font-medium`
const LinkListItem = styled.li`
  ${tw`mt-4`}
  a {
    ${tw`border-b-2 border-transparent hocus:border-gray-100 pt-3 pb-1 transition duration-300 uppercase`}
  }
`
const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`

const CopywrightNotice = tw.p`text-center text-xs sm:text-base mt-8 md:mt-0 font-light text-gray-100`

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`

const socialLinksMap = {
  facebook: <FacebookIcon />,
  "facebook.com": <FacebookIcon />,
  "www.facebook.com": <FacebookIcon />,
  twitter: <TwitterIcon />,
  "twitter.com": <TwitterIcon />,
  "www.twitter.com": <TwitterIcon />
}

export const MainFooter = ({
  siteName,
  copyright,
  contactsText,
  titleLegal,
  titleNavigation,
  titleLocation,
  links = [],
  legalLinks = [],
  socialLinks = []
}) => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <Column>
            <ColumnHeading>{titleNavigation}</ColumnHeading>
            <LinkList>
              {links.map((link) => (
                <LinkListItem key={`main-${link.key}`}>{link}</LinkListItem>
              ))}
            </LinkList>
          </Column>

          <Column>
            <ColumnHeading>{titleLegal}</ColumnHeading>
            <LinkList>
              {legalLinks.map((link) => (
                <LinkListItem key={`legal-${link.key}`}>{link}</LinkListItem>
              ))}
            </LinkList>
          </Column>

          <DesktopColumn></DesktopColumn>
          <DesktopColumn></DesktopColumn>

          <Column>
            <ColumnHeading>{titleLocation}</ColumnHeading>
            <LinkList>
              <LinkListItem>{contactsText}</LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <ThreeColRow>
          <CopywrightNotice>{copyright.replace("{year}", new Date().getFullYear())}</CopywrightNotice>
          <SocialLinksContainer>
            {socialLinks.map((link) => (
              <SocialLink
                key={`social-${link.key}`}
                title={link.title}
                href={link.href}
                rel="noreferrer nofollow"
                target="_blank"
              >
                {socialLinksMap[link.icon] ? socialLinksMap[link.icon] : null}
              </SocialLink>
            ))}
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  )
}

export default MainFooter
