/* eslint-disable no-undef */
import { useEffect, useState, useCallback, useRef } from "react"
import { useDebouncedCallback } from "use-debounce"
import { getScrollTop, isBrowser } from "./common"

const SCROLL_UP = "up"
const SCROLL_DOWN = "down"
const TOP = "top"

export default function useScrollDirection(throttle = 100) {
  const lastScrollY = useRef(getScrollTop())
  const [scrollDir, setScrollDir] = useState(TOP)

  const handleScroll = useDebouncedCallback(
    useCallback(() => {
      const scrollY = getScrollTop()
      setScrollDir(scrollY === 0 ? TOP : scrollY > lastScrollY.current ? SCROLL_DOWN : SCROLL_UP)
      lastScrollY.current = scrollY
    }, [setScrollDir]),
    throttle,
    { maxWait: throttle }
  )

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  return scrollDir
}
