import "tailwindcss/dist/base.css"
import "../styles/globalStyles.css"
import React from "react"
import tw from "twin.macro"
//
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`

export const Layout = ({ children }) => <StyledDiv>{children}</StyledDiv>

export default Layout
