import React from "react"
import { CookiesProvider } from "react-cookie"
//
import { LocationContextProvider } from "./Location"

export const SiteDataContext = React.createContext({})
export const PreviewContext = React.createContext({})

export const PageWrapper = ({ children, location, data }) => {
  if (location.pathname === "/offline-plugin-app-shell-fallback/") {
    return null
  }

  const preview = location.pathname.startsWith("/preview/")

  const { contentfulSite } = data

  return (
    <LocationContextProvider value={location}>
      <SiteDataContext.Provider value={contentfulSite}>
        <CookiesProvider>
          <PreviewContext.Provider value={preview}>{children}</PreviewContext.Provider>
        </CookiesProvider>
      </SiteDataContext.Provider>
    </LocationContextProvider>
  )
}

export default PageWrapper
