import React from "react"
import tw, { styled } from "twin.macro"
import { SectionHeading } from "../misc/Headings"
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons"

const Container = tw.div`relative`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-4 md:py-24`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 md:self-center`
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-auto md:h-auto`
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-4 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
])

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`

const StyledImage = styled.div`
  height: 100% !important;
  ${tw`rounded bg-cover bg-center h-auto self-auto`}
  .gatsby-image-wrapper {
    height: 100%;
  }
  .gatsby-image-wrapper picture > img {
    ${tw`max-h-full`}
  }
`

const TextContent = tw.div`lg:py-8`

const Heading = tw(
  SectionHeading
)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight mt-0`

const Description = styled.div`
  ${tw`text-center md:text-left text-sm md:text-base lg:text-xl font-medium leading-relaxed text-secondary-400 mt-4`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6 text-primary-500`}
  }
  h4 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    padding-left: 1em;
    ${tw`md:list-disc pl-4 pt-1 list-outside mt-6 md:pr-16`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  a {
    ${tw`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`};
  }
  img {
    ${tw`py-4 lg:py-6`}
  }
`

const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-primary-500`
const Key = tw.div`font-medium text-gray-700`
const CenteredDiv = tw.div`text-center md:text-left`

export const SideImageBlock = ({ heading, description, image, textOnLeft = false, cta, statistics }) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <StyledImage>{image}</StyledImage>
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            {statistics ? (
              <Statistics>
                {statistics.map((statistic, index) => (
                  <Statistic key={index}>
                    <Value>{statistic.value}</Value>
                    <Key>{statistic.key}</Key>
                  </Statistic>
                ))}
              </Statistics>
            ) : null}
            <CenteredDiv>{cta && <PrimaryButton aria-label="ver mais">{cta}</PrimaryButton>}</CenteredDiv>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  )
}

export default SideImageBlock
