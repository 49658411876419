import React from "react"
//

export const getSlug = (slug) => {
  return slug
}

export const multilineText = (title) => {
  if (!title || typeof title !== "string") {
    return ""
  }

  const parts = title?.trim()?.split(/(?:\r\n|\r|\n|\\n)/g) || []
  const length = parts.length

  return parts.map((value, idx) => (
    <React.Fragment key={`text-${Math.random()}`}>
      {value}
      {idx < length - 1 ? <br /> : null}
    </React.Fragment>
  ))
}

export const slugify = (text) =>
  text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")

export const shuffleArray = (arr) =>
  arr
    .map((a) => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map((a) => a[1])
