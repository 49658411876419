import React from "react"
import tw, { styled } from "twin.macro"
import Slider from "react-slick"

import { ArrowLeft3Icon, ArrowRight3Icon } from "../../images/Icons"

import { multilineText } from "../../utils"

import "slick-carousel/slick/slick.css"

const StyledSlider = styled(Slider)`
  ${tw`flex mx-auto w-full text-left text-white`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
`

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-gradient-to-br from-primary-500 via-black to-black opacity-70`

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center text-center`

const Heading = styled.h1`
  ${tw`mx-auto sm:max-w-xs md:max-w-2xl lg:max-w-3xl xl:max-w-4xl text-3xl text-center sm:text-4xl lg:text-5xl xl:text-5xl font-black text-white leading-snug sm:mt-0 sm:mb-8 md:mb-12`}
  span {
    ${tw`inline-block mt-2`}
  }
`

const SubHeading = styled.div`
  ${tw`mx-auto sm:max-w-xs md:max-w-2xl lg:max-w-3xl xl:max-w-4xl text-lg text-center max-w-4xl sm:text-lg lg:text-xl xl:text-2xl font-normal text-white leading-snug mt-2 sm:leading-6 md:leading-10`}
  span {
    ${tw`inline-block mt-2`}
  }
`

const StyledPrimaryAction = styled.div`
  ${tw`mt-10`}
  button, a {
    ${tw`inline-block px-8 py-3 sm:px-8 sm:py-4 animate-bounce rounded-3xl text-sm sm:text-base sm:mt-16 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:ring`}
    span {
      ${tw`rounded animate-bounce duration-300 w-5 h-5 text-white`}
    }
  }
`

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-white hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  > * {
    width: 100%;
    height: 100%;
  }
`

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props} aria-label="ver seguinte">
      <ArrowRight3Icon />
    </button>
  </SliderControlButtonContainer>
)
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props} aria-label="ver anterior">
      <ArrowLeft3Icon />
    </button>
  </SliderControlButtonContainer>
)

export const HeroCenteredContent = ({ slides = [], backgroundImage, cta = false }) => {
  const [, setSliderRef] = React.useState(null)

  return (
    <Container>
      <OpacityOverlay />
      <Image>{backgroundImage}</Image>
      <HeroContainer>
        <Content>
          <StyledSlider
            nextArrow={<NextArrow />}
            prevArrow={<PreviousArrow />}
            ref={setSliderRef}
            autoplay
            autoplaySpeed={7000}
            accessibility
          >
            {slides?.map((slide, idx) => (
              <div key={`slide-${slide.id}-${idx}`}>
                <Heading>{multilineText(slide?.heading)}</Heading>
                <SubHeading>{slide?.subHeading}</SubHeading>
              </div>
            ))}
          </StyledSlider>
          {cta && <StyledPrimaryAction>{cta}</StyledPrimaryAction>}
        </Content>
      </HeroContainer>
    </Container>
  )
}

export default HeroCenteredContent
