/* eslint-disable no-useless-escape */
import React from "react"
import tw, { styled } from "twin.macro"

const Container = tw.div`relative px-0 md:px-2 `
const Content = tw.div`max-w-screen-xl mx-auto py-2 lg:py-4 px-0 md:px-2`

const FormContainer = styled.div`
  ${tw`p-4 md:p-10 lg:p-12 bg-secondary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full text-gray-100 text-base tracking-wide py-2 text-gray-100 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
  input.conditions {
    width: auto !important;
  }
`

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`
const Column = tw.div`sm:w-5/12 flex flex-col`
const InputContainer = tw.div`relative py-5 mt-6`
const RowTermsConditions = tw.div`w-full flex`
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm pb-2`
const Input = tw.input`p-2 border-none bg-secondary-400 mt-2 hocus:bg-secondary-200`
const TextArea = tw.textarea`h-24 p-2 sm:h-full resize-none bg-secondary-400 border-none mt-2 hocus:bg-secondary-200`
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-secondary-500 rounded font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:ring hover:bg-gray-300 hover:text-secondary-700 hocus:-translate-y-px hocus:shadow-xl`

const Message = tw.h3`text-2xl mt-8 text-white py-2 px-4`
const SuccessMessage = tw(Message)`bg-green-700`
const ErrorMessage = tw(Message)`bg-red-700`

const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const ContactsFormBlock = ({ title, onSubmit, labels, messages, formName = "contactos" }) => {
  const [status, setStatus] = React.useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { name, email, message } = e.target
    const data = {
      nome: name?.value,
      email: email?.value,
      mensagem: message?.value
    }

    if (!data.email || !re.test(data.email) || !data.nome || !data.mensagem) {
      setStatus("invalid")
      return
    }

    setStatus("submitting")
    const result = await onSubmit(data)

    setStatus(result?.status === "processed" ? result.status : "error")
  }

  return (
    <Container id="form-contacto">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>{title}</h2>
            {(status === "" || status === "submitting" || status === "invalid") && (
              <form onSubmit={handleSubmit} name={formName}>
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">{labels?.name}</Label>
                      <Input aria-label="o seu nome" id="name-input" type="text" name="name" required />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">{labels?.email}</Label>
                      <Input aria-label="o seu email" id="email-input" type="email" name="email" required />
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="message-input">{labels?.message}</Label>
                      <TextArea aria-label="a sua mensagem" id="message-input" name="message" required />
                    </InputContainer>
                  </Column>
                </TwoColumn>

                {labels?.termsAndConditions && (
                  <RowTermsConditions tw="mt-3 text-left">
                    <label tw="flex">
                      <input className="conditions" name="condicoes" type="checkbox" tw="h-5 w-1" required />
                      <span tw="ml-2 text-white text-xs">{labels?.termsAndConditions}</span>
                    </label>
                  </RowTermsConditions>
                )}

                <SubmitButton
                  aria-label="Pressione para enviar"
                  type="submit"
                  value="Submit"
                  disabled={status === "submitting" ? true : false}
                >
                  {status === "submitting" ? "Processando" : "Enviar"}
                </SubmitButton>
              </form>
            )}

            {status === "processed" && <SuccessMessage>{messages?.success}</SuccessMessage>}
            {status === "error" && <ErrorMessage>{messages?.error}</ErrorMessage>}
          </div>
        </FormContainer>
      </Content>
    </Container>
  )
}

export default ContactsFormBlock
