/* eslint-disable no-useless-escape */
import React, { useState, useLayoutEffect } from "react"
import tw from "twin.macro"
import { PrimaryButton, SecondaryButton } from "../misc/Buttons"

const Container = tw.div`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`
const CancelButton = tw(SecondaryButton)`w-full sm:w-1/4 md:w-1/4 mt-6 sm:mt-0 sm:rounded-l-none py-4 hover:bg-gray-400`
const SubmitButton = tw(
  PrimaryButton
)`w-full sm:w-1/4 md:w-1/4 mt-6 sm:mt-0 sm:rounded-l-none py-4 text-gray-100 hocus:text-gray-300 hover:bg-primary-800 disabled:opacity-50`
const InputContainer = tw.div`relative py-5 mt-6`
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm pb-2`
const Input = tw.input`p-2 border-none bg-secondary-400 mt-2 hocus:bg-secondary-200 w-full text-white`
const Message = tw.h3`text-xl mt-8 text-white py-2 px-4`
const SuccessMessage = tw(Message)`bg-green-700`
const ErrorMessage = tw(Message)`bg-red-700`
const WarnMessage = tw(Message)`bg-yellow-600`

const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  function useLockBodyScroll() {
    useLayoutEffect(() => {
      // Get original body overflow
      const originalStyle = window.getComputedStyle(document.body).overflow
      // Prevent scrolling on mount
      document.body.style.overflow = "hidden"
      // Re-enable scrolling when component unmounts
      return () => (document.body.style.overflow = originalStyle)
    }, []) // Empty array ensures effect is only run on mount and unmount
  }

export const RegisterModalForm = ({ showModal, onClose, onSubmit }) => {
  const [status, setStatus] = useState("")

  useLockBodyScroll();

  if (!showModal) {
    return null
  }

  const handleSubmit = async (e) => {
    setStatus("submitting")
    e.preventDefault()

    const { name, email, phone, habilitacoes } = e.target

    const data = {
      nome: name?.value,
      email: email?.value,
      telefone: phone?.value,
      habilitacoes: habilitacoes?.value
    }

    if (!data.email || !re.test(data.email) || !data.nome || !data.telefone || !data.habilitacoes) {
      setStatus("invalid")
      return
    }

    const result = await onSubmit(data)

    setStatus(result?.status === "processed" ? result.status : "error")
  }

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit} name="register">
          <div tw="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div tw="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div tw="flex items-start justify-between p-5 border-b border-solid rounded-t">
                <h3 tw="text-3xl font-semibold text-primary-500 w-full sm:w-[600px] md:w-[800px]">
                  Efetuar Pre-Inscricão
                </h3>
                <button
                  tw="p-1 ml-auto border-0 text-gray-900 float-right text-4xl leading-none font-semibold"
                  onClick={onClose}
                >
                  <span tw="text-gray-900 opacity-25 h-6 w-6 text-3xl block outline-none focus:outline-none">×</span>
                </button>
              </div>
              {/*body*/}
              <div tw="relative p-6 flex-auto">
                <div tw="my-4 text-lg leading-relaxed">
                  {status === "processed" && (
                    <SuccessMessage>Envio de Pre-Inscricão efetuado com sucesso!</SuccessMessage>
                  )}
                  {status === "error" && (
                    <ErrorMessage>Ocorreu um erro ao efetuar Pre-Inscricão, tente novamente mais tarde.</ErrorMessage>
                  )}
                  {status === "invalid" && (
                    <WarnMessage>Dados inválidos. Por favor verifique os dados e tente novamente.</WarnMessage>
                  )}
                </div>
              </div>
              {status !== "processed" && (
                <div tw="relative px-6">
                  <InputContainer>
                    <Label htmlFor="name-input">Nome</Label>
                    <Input aria-label="o seu nome" id="name-input" type="text" name="name" required />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Email</Label>
                    <Input aria-label="o seu email" id="email-input" type="email" name="email" required />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="phone-input">Telefone</Label>
                    <Input aria-label="o seu telefone" id="phone-input" type="text" name="phone" required />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="habilitacoes-input">Habilitações</Label>
                    <Input
                      aria-label="o seu telefone"
                      id="habilitacoes-input"
                      type="text"
                      name="habilitacoes"
                      required
                    />
                  </InputContainer>
                </div>
              )}
              {/*footer*/}
              <div tw="flex items-center justify-end p-6 border-t border-solid rounded-b gap-4">
                {status === "processed" ? (
                  <CancelButton type="button" onClick={onClose}>
                    Fechar
                  </CancelButton>
                ) : (
                  <>
                    <CancelButton type="button" onClick={onClose}>
                      Cancelar
                    </CancelButton>
                    <SubmitButton type="submit" disabled={status === "submitting" ? true : false} value="Submit">
                      {status === "submitting" ? "Processando" : "Enviar"}
                    </SubmitButton>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </Container>
      <div tw="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default RegisterModalForm
