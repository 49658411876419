import React from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import useWindowScrollPosition from "@rehooks/window-scroll-position"
import { useWindowHeight } from "@react-hook/window-size"
import tw, { styled } from "twin.macro"
//
import Media from "../../storybook/utils/media"

const Progress = styled.div`
  position: fixed;

  ${Media({
    display: ["none", "none", "none", "inline-block"]
  })}

  bottom: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  z-index: 999;
  .CircularProgressbar-text {
    ${tw`fill-current`};
  }
  .CircularProgressbar-path {
    ${tw`stroke-current`};
  }
  transition: transform 0.2s ease;

  &:hover {
    cursor: pointer;
    transform: translateY(-10px);

    &::after {
      content: "⇡";
      color: #fff;
      position: absolute;
      text-align: center;
      top: 10px;
      left: 0;
      right: 0;
    }
    .CircularProgressbar-background {
      opacity: 1;
    }
    .CircularProgressbar-trail {
    }
    .CircularProgressbar-path {
      opacity: 1;
    }
    .CircularProgressbar-text {
      opacity: 0;
    }
  }
`

export const BackToTop = () => {
  const position = useWindowScrollPosition({ throttle: 100 })
  const windowHeight = useWindowHeight()
  const percent = Math.round((position.y / (document.body.clientHeight - windowHeight)) * 100)

  return percent > 10 ? (
    <Progress
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      }}
    >
      <CircularProgressbar
        background
        backgroundPadding={8}
        styles={buildStyles({
          pathColor: "#777",
          textColor: "#e0e0e0",
          backgroundColor: "#333",
          trailColor: "transparent"
        })}
        value={percent > 100 ? 100 : percent}
        text={`${percent > 100 ? 100 : percent}%`}
      />
    </Progress>
  ) : null
}

export default BackToTop
