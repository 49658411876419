import React from "react"
import tw, { styled } from "twin.macro"
//eslint-disable-next-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings"
import { SectionSmallBlockRichText } from "../misc/Typography"
import { multilineText } from "../../utils"

const Container = tw.div`relative`

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`
const Subheading = tw(SubheadingBase)`mb-4`
const Heading = tw(SectionHeading)`w-full`
const Description = tw(SectionSmallBlockRichText)`w-full text-center`

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm py-4`}
`

const Card = styled.div`
  ${tw`flex flex-col flex-wrap sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-4 py-8 border-gray-100 rounded-3xl bg-gradient-to-t from-gray-200 to-gray-100 hover:from-gray-100 hover:to-white transition duration-300`}

  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`ml-0 mt-4 sm:mt-2 text-center w-full`}
  }

  .title {
    min-height: 3rem;
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none content-center flex items-center justify-center`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }

  .image {
    > div {
      min-height: 150px;
    }
  }

  .cta {
    ${tw`mt-auto w-full`}
  }
`

export const InfoGridBlock = ({ heading, subheading, description, cards = [], solidBg, imageTop = false }) => (
  <Container css={[solidBg && tw`bg-gray-300 w-screen -ml-8 -mr-8`]}>
    <ThreeColumnContainer>
      {subheading && <Subheading>{subheading}</Subheading>}
      <Heading>{multilineText(heading)}</Heading>
      {description && <Description>{multilineText(description)}</Description>}
      <VerticalSpacer />
      {cards?.map((card, idx) => (
        <Column key={`block-${card?.id || idx}`}>
          <Card>
            {card?.image && imageTop && <div className="image">{card.image}</div>}
            <div className="textContainer">
              <div className="title">{card?.title}</div>
              <div className="description">{card?.description}</div>
              {card?.image && !imageTop && <div className="image">{card.image}</div>}
            </div>
            {card?.cta && <div className="cta">{card.cta}</div>}
          </Card>
        </Column>
      ))}
    </ThreeColumnContainer>
  </Container>
)

export default InfoGridBlock
