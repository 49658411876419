import tw, { styled } from "twin.macro"
//
export const SectionDescription = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`
export const SectionDescriptionContainer = styled.div`
  ${tw`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`}
  a {
    ${tw`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`};
  }
`
export const SectionBlockRichText = styled.div`
  ${tw`text-base text-gray-800`}

  p {
    ${tw`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-600 max-w-xl`}
  }
  h2 {
    ${tw`text-xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-lg font-bold mt-6`}
  }
  h4 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  a {
    ${tw`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`};
  }
  img {
    ${tw`py-4 lg:py-6`}
  }
`
export const SectionSmallBlockRichText = styled.div`
  ${tw`text-base text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h2 {
    ${tw`text-xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-lg font-bold mt-6`}
  }
  h4 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  a {
    ${tw`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`};
  }
  img {
    ${tw`py-4 lg:py-6`}
  }
`
export const SectionBlockListRichText = styled.dd`
  ${tw`text-base text-gray-800`}

  p {
    ${tw`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-600`}
  }
  h2 {
    ${tw`text-xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-lg font-bold mt-6`}
  }
  h4 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  a {
    ${tw`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`};
  }
  img {
    ${tw`py-4 lg:py-6`}
  }
`
