export const isBrowser = typeof window !== `undefined`

export function getScrollTop() {
  return isBrowser ? window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop : 0
}

export const queryParams = (params) =>
  Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&")
