import React from "react"
import tw, { styled } from "twin.macro"
//eslint-disable-next-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings"
import { PrimaryButton as PrimaryButtonBase } from "../misc/Buttons"
import { multilineText } from "../../utils"

const Container = tw.div`relative`

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto`}
`

const BottomContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto`}
`

const Heading = tw(SectionHeading)`w-full`

const PrimaryButton = styled(PrimaryButtonBase)`
  ${tw`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`}
`

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/3 max-w-sm mb-4`}
`

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start rounded-xl text-center sm:text-left h-full mx-4 px-2 py-8 bg-gradient-to-t from-gray-200 to-gray-100 hover:from-gray-100 hover:to-white`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-normal text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`

export const NewsColumn = (item) => (
  <Column>
    <Card>
      <span className="textContainer">
        <span className="title">{item?.title}</span>
      </span>
    </Card>
  </Column>
)

export const InfoNewsBlock = ({ heading, news = null, primaryAction = null }) => {
  if (!news) {
    return null
  }

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{multilineText(heading)}</Heading>
        <VerticalSpacer />
        {news}
      </ThreeColumnContainer>
      <BottomContainer>{primaryAction && <PrimaryButton>{primaryAction}</PrimaryButton>}</BottomContainer>
    </Container>
  )
}

export default InfoNewsBlock
