import React from "react"
import tw, { styled } from "twin.macro"
import { Popover, ArrowContainer } from "react-tiny-popover"
import { ChevronLeft as ChevronLeftIcon, ArrowRight as ArrowRightIcon } from "react-feather"
//
import { SiteDataContext, LocationContext } from "../layout"
import StorybookHeader, { LogoLink } from "../../storybook/components/headers/light"
import { getNavigation, NavLink, isNavigationActive } from "../../utils/navigation"
import useScrollDirection from "../../utils/use-scroll-direction"
import HeaderLogo from "./logos"

const StyledHeader = styled(StorybookHeader)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 65px;
  z-index: 49;
  padding: 0px;
`

const StyledHeaderLight = styled(StyledHeader)`
  color: white;
`

const StyledHeaderDark = styled(StyledHeader)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`

const StyledSubNavLink = styled(NavLink)`
  ${tw`flex py-1 hocus:underline`}
  &.active {
    ${tw`font-light text-gray-700`}
  }
`

const HeaderNavigation = ({ links, site, location, scrollDir, mode }) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)

  React.useEffect(() => {
    if (scrollDir === "up" && isPopoverOpen) {
      setIsPopoverOpen(false)
    }
  }, [scrollDir, isPopoverOpen])

  return (
    <ul tw="list-none">
      {links?.map((link, idx) => {
        if (link.subMenus?.length) {
          const navigation = getNavigation(link, site)

          return (
            <Popover
              key={`nav-${link.id}-${idx}`}
              isOpen={isPopoverOpen}
              positions={["bottom"]}
              padding={10}
              onClickOutside={() => setIsPopoverOpen(false)}
              containerClassName="popover-container"
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor={"white"}
                  arrowSize={10}
                  className="popover-arrow-container"
                  arrowClassName="popover-arrow"
                >
                  <ul
                    tw="bg-white w-auto px-4 py-3 pr-8"
                    css={[mode === "dark" ? tw`rounded-none shadow shadow-lg` : tw`rounded-lg shadow-md`]}
                  >
                    {link.subMenus?.map((sublink) => {
                      if (sublink.__typename === "ContentfulTextoSubMenu") {
                        return (
                          <li tw="text-lg text-gray-700 font-semibold mt-4 mb-1" key={`nav-${link.id}-${sublink.id}`}>
                            {sublink.titulo}
                          </li>
                        )
                      }

                      const subNavigation = getNavigation(sublink, site)

                      return (
                        <li tw="pl-2 flex items-center" key={`nav-${link.id}-${sublink.id}`}>
                          <ArrowRightIcon size={12} tw="mr-2" />
                          <StyledSubNavLink {...subNavigation} />
                        </li>
                      )
                    })}
                  </ul>
                </ArrowContainer>
              )}
            >
              <li className={(isNavigationActive(navigation.to, location) && "active") || ""}>
                <NavLink
                  active={isNavigationActive(navigation.to, location)}
                  {...navigation}
                  onClick={(e) => {
                    e.preventDefault()
                    setIsPopoverOpen(!isPopoverOpen)
                  }}
                />
              </li>
            </Popover>
          )
        } else {
          const navigation = getNavigation(link, site)

          return (
            <li key={`nav-${link.id}`} className={(isNavigationActive(navigation.to, location) && "active") || ""}>
              <NavLink active={isNavigationActive(navigation.to, location)} {...navigation} />
            </li>
          )
        }
      })}
    </ul>
  )
}

const MobileHeaderNavigation = ({ links, site, location }) => {
  const [activeItem, setActiveItem] = React.useState(null)

  if (activeItem) {
    return (
      <div>
        <button tw="uppercase flex items-center mb-8 px-1 py-1 rounded-md" onClick={() => setActiveItem(null)}>
          <ChevronLeftIcon size={28} />
          <span tw="uppercase text-sm">voltar</span>
        </button>
        <ul tw="list-none flex-col">
          {activeItem.subMenus?.map((sublink) => {
            if (sublink.__typename === "ContentfulTextoSubMenu") {
              return (
                <li tw="text-lg text-primary-200 font-semibold mt-4 mb-1" key={`nav-${activeItem.id}-${sublink.id}`}>
                  {sublink.titulo}
                </li>
              )
            }

            const subNavigation = getNavigation(sublink, site)

            return (
              <li key={`nav-${activeItem.id}-${sublink.id}`}>
                <StyledSubNavLink {...subNavigation} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <ul tw="list-none flex-col">
      {links?.map((link, idx) => {
        if (link.subMenus?.length) {
          const navigation = getNavigation(link, site)
          return (
            <li className={(isNavigationActive(navigation.to, location) && "active") || ""}>
              <NavLink
                active={isNavigationActive(navigation.to, location)}
                {...navigation}
                onClick={(e) => {
                  e.preventDefault()
                  setActiveItem(link)
                }}
              />
            </li>
          )
        } else {
          const navigation = getNavigation(link, site)

          return (
            <li key={`nav-${link.id}`} className={(isNavigationActive(navigation.to, location) && "active") || ""}>
              <NavLink active={isNavigationActive(navigation.to, location)} {...navigation} />
            </li>
          )
        }
      })}
    </ul>
  )
}

export const Header = ({ headerStyle = "light", searchActive = false, handleSearch = () => {} }) => {
  const site = React.useContext(SiteDataContext)
  const location = React.useContext(LocationContext)
  const scrollDir = useScrollDirection()

  const { headerNavegacaoPrincipal } = site

  return headerStyle === "light" ? (
    <StyledHeaderLight
      sticky={!searchActive && scrollDir === "up"}
      logoLink={scrollDir === "up" ? <HeaderLogo mode="dark" /> : <HeaderLogo mode="light" />}
      links={
        <HeaderNavigation
          scrollDir={scrollDir}
          links={headerNavegacaoPrincipal}
          site={site}
          location={location}
          mode={scrollDir === "up" ? "dark" : "light"}
        />
      }
      mobileLinks={<MobileHeaderNavigation links={headerNavegacaoPrincipal} site={site} location={location} />}
      handleSearch={handleSearch}
      searchActive={searchActive}
    />
  ) : (
    <StyledHeaderDark
      sticky={!searchActive && scrollDir === "up"}
      logoLink={<HeaderLogo mode="dark" />}
      links={
        <HeaderNavigation scrollDir={scrollDir} links={headerNavegacaoPrincipal} site={site} location={location} />
      }
      mobileLinks={<MobileHeaderNavigation links={headerNavegacaoPrincipal} site={site} location={location} />}
      handleSearch={handleSearch}
      searchActive={searchActive}
    />
  )
}

export default Header
