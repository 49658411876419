import React, { useEffect, useRef, useState } from "react"
import tw, { styled } from "twin.macro"
import { useStaticQuery, graphql } from "gatsby"
import { useFlexSearch } from "react-use-flexsearch"
//
import { NavLink } from "../../utils/navigation"
import { CloseIcon } from "../../storybook/images/Icons"
//

const Container = styled.div`
  ${tw`fixed inset-0 z-50 w-screen h-screen bg-gray-900 bg-opacity-95 backdrop-filter backdrop-blur-xl`}
`

const Heading = styled.h1`
  ${tw`text-gray-200 text-3xl`}
`

const InnerContainer = styled.div`
  ${tw`w-screen h-screen flex items-center px-5 py-5 relative mx-auto flex-col w-full`}
  svg {
    width: 20px;
    height: 20px;
  }
`

const InputContainer = styled.div`
  ${tw`text-gray-200 m-5 w-6/12 flex flex-col h-screen items-center mt-10`}
`

const Input = styled.input`
  ${tw`min-w-full py-4 text-2xl pl-8 rounded bg-gray-200 bg-opacity-15 placeholder-gray-300 focus:bg-opacity-15 focus:text-gray-100 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:border-transparent`}
`

const SearchContainer = styled.div`
  ${tw`absolute right-0 top-0 z-50`}
`

const SearchButton = styled.button`
  ${tw`flex p-1 md:p-5 text-gray-100 items-center`}
  svg {
    min-width: 22px;
    min-height: 22px;
  }
`

const searchOptions = {
  limit: 10,
  suggest: true,
  bool: "and"
}

const Search = ({ onClose, enabled = false }) => {
  const queryData = useStaticQuery(graphql`
    query {
      localSearchPages {
        index
        store
      }
    }
  `)

  const index = queryData.localSearchPages.index
  const store = queryData.localSearchPages.store

  const inputRef = useRef()
  const [query, setQuery] = useState("")
  const results = useFlexSearch(query, index, store, searchOptions)

  useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  if (!enabled) {
    return null
  }

  const Result = ({ title, path }) => {
    return (
      <NavLink to={path} textoAccessivel={`Navegar para resultado em ${path}`} onClick={onClose}>
        <li tw="pl-0 md:pl-8 pr-2 text-xl md:text-2xl py-1 relative cursor-pointer hover:bg-secondary-100 hover:bg-opacity-5 text-gray-300 hover:text-white">
          {title}
        </li>
      </NavLink>
    )
  }

  return (
    <Container>
      <SearchContainer>
        <SearchButton onClick={onClose}>
          <CloseIcon tw="fill-current text-white" />
          <span>Fechar</span>
        </SearchButton>
      </SearchContainer>
      <InnerContainer>
        <Heading>Pesquisar</Heading>
        <InputContainer>
          <Input
            ref={inputRef}
            type="text"
            tw=""
            placeholder="pesquisar ..."
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
          {query.length ? <h3 tw="mt-2 text-sm">{results.length} Resultados</h3> : null}
          {results.length ? (
            <ul tw="text-gray-600 w-full mt-2">
              {results.map((result) => (
                <Result key={`result-${result.id}`} title={result?.title} path={result?.path} />
              ))}
            </ul>
          ) : null}
        </InputContainer>
      </InnerContainer>
    </Container>
  )
}

export default Search
