import React from "react"
import tw, { styled } from "twin.macro"
//

const Container = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  h4 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-outside pl-8 pt-2`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
  a {
    ${tw`cursor-pointer font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`};
  }
  img {
    ${tw`py-4 lg:py-6`}
  }
`

export const TextBlock = ({ text }) => {
  return <Container>{text}</Container>
}

export default TextBlock
