import facepaint from "facepaint"
//
export const small = 576
export const medium = 768
export const large = 992
export const xlarge = 1200

export const breakpoints = [small, medium, large, xlarge]

export default facepaint([
  `@media(min-width: ${small}px)`,
  `@media(min-width: ${medium}px)`,
  `@media(min-width: ${large}px)`,
  `@media(min-width: ${xlarge}px)`
])
