/* eslint-disable react/jsx-filename-extension */
import React from "react"
//
import { PageWrapper } from "./src/components/layout"

// import "tailwindcss/dist/base.min.css"

const theme = process.env.THEME

/**
 * Implements Gatsby wrapPageElement(params).
 */

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper {...props} theme={theme}>
    {element}
  </PageWrapper>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}
