import React from "react"
import tw, { styled } from "twin.macro"
import { ContentWithPaddingXl, Container } from "../misc/Layouts"

const PrimaryBackgroundContainer = tw.div`py-16 lg:py-20 bg-gradient-to-r from-purple-200 to-purple-300 rounded-lg relative`
const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`
const Subheading = tw.p`text-primary-800 opacity-75 text-2xl`
const Heading = tw.p`text-primary-500`

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`

const Button = styled.button`
  ${tw`mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0`}
  a {
    ${tw`flex flex-grow justify-center w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5  rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:ring`}
  }
`
const PrimaryAction = styled(Button)`
  a {
    ${tw`shadow text-gray-100 hocus:text-gray-300 bg-primary-500 hocus:bg-primary-700`}
  }
`

const SecondaryAction = styled(Button)`
  a {
    ${tw`text-primary-500 hover:text-primary-600 bg-gray-100 hover:bg-gray-200`}
  }
`

export const GetStartedDark = ({ heading, subheading, primaryAction, secondaryAction, pushDownFooter = true }) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
            </TextContainer>
            <LinksContainer>
              <PrimaryAction aria-label="ver mais">{primaryAction}</PrimaryAction>
              {secondaryAction && <SecondaryAction aria-label="ver mais">{secondaryAction}</SecondaryAction>}
            </LinksContainer>
          </Row>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  )
}

export default GetStartedDark
