import React from "react"
import tw, { styled } from "twin.macro"
//
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed"

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`

const OpacityOverlay = tw.div`z-10 absolute inset-0 opacity-75 bg-gradient-to-b from-primary-900 via-primary-700 to-transparent`

const HeroContainer = tw.div`z-20 relative px-2 sm:px-8 max-w-screen-xl mx-auto`
const TwoColumn = tw.div`pt-24 pb-32 px-2 flex justify-between items-center flex-col lg:flex-row`
const LeftColumn = tw.div`flex flex-col items-center lg:block`
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none mt-2`}
`

const SubHeading = styled.h2`
  ${tw`text-xl text-center lg:text-left sm:text-xl lg:text-2xl xl:text-3xl font-normal text-gray-100 leading-none mt-2`}
`

const PrimaryAction = styled.button`
  ${tw`sm:mt-16 mt-10`}
  a {
    ${tw`flex flex-grow px-8 py-3 text-sm sm:text-base sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:ring`}
  }
`

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`

const StyledResponsiveImage = styled.div`
  ${tw`rounded`}
`

const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  > * {
    width: 100%;
    height: 100%;
  }
`

export const HeroSideVideo = ({ heading1, heading2, cta, backgroundImage, videoUrl, image, size = null }) => (
  <Container
    css={[
      videoUrl && tw`h-full max-h-full`,
      size === "small"
        ? `min-height: 300px; height: 300px`
        : size === "large"
        ? `min-height: 500px; height: 500px`
        : `min-height: 400px`
    ]}
  >
    <OpacityOverlay />
    <Image>{backgroundImage}</Image>
    <HeroContainer>
      <TwoColumn>
        <LeftColumn>
          <Heading css={[size === "small" && tw`text-5xl`]}>{heading1}</Heading>
          {heading2 ? <SubHeading>{heading2}</SubHeading> : null}
          {cta && <PrimaryAction aria-label={cta?.textoAccessivel || cta?.titulo || "ver mais"}>{cta}</PrimaryAction>}
        </LeftColumn>
        <RightColumn>
          {videoUrl && <StyledResponsiveVideoEmbed url={videoUrl} background="transparent" />}
          {!videoUrl && image && <StyledResponsiveImage>{image}</StyledResponsiveImage>}
        </RightColumn>
      </TwoColumn>
    </HeroContainer>
  </Container>
)

export default HeroSideVideo
