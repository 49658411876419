import React from "react"
import tw, { styled } from "twin.macro"
import { ContentWithPaddingXl, Container } from "../misc/Layouts"

const PrimaryBackgroundContainer = tw.div`py-20 lg:py-24 bg-gradient-to-r from-primary-400 to-primary-600 rounded-lg relative`
const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`
const Text = tw.h4`text-gray-100 text-2xl sm:text-3xl font-bold`
const Subheading = tw.h5`text-gray-100 opacity-75 text-2xl sm:text-3xl`

const LinksContainer = tw(ColumnContainer)`flex justify-center lg:justify-end mt-6 lg:mt-0 flex-col sm:flex-row`

const Button = styled.button`
  ${tw`mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0`}
  a {
    ${tw`flex flex-grow w-full justify-center sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:ring`}
  }
`
const PrimaryAction = styled(Button)`
  a {
    ${tw`bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`}
  }
`

const SecondaryAction = styled(Button)`
  a {
    ${tw`bg-gray-100 text-primary-500 border-gray-500 hover:bg-gray-200 hover:text-primary-600 hover:border-primary-600`}
  }
`

export const GetStarted = ({ heading, subheading, primaryAction, secondaryAction, pushDownFooter = true }) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Text>{heading}</Text>
            </TextContainer>
            <LinksContainer>
              <PrimaryAction>{primaryAction}</PrimaryAction>
              {secondaryAction && <SecondaryAction>{secondaryAction}</SecondaryAction>}
            </LinksContainer>
          </Row>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  )
}

export default GetStarted
