import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
//

export const ImageFluid = ({ image, alt = "", className }) => {
  return image?.gatsbyImageData ? <GatsbyImage image={image.gatsbyImageData} alt={alt} className={className} /> : null
}

export const ImageMobileFluid = ({ image, alt = "", className }) => {
  return image?.gatsbyImageData ? <GatsbyImage image={image.gatsbyImageData} alt={alt} className={className} /> : null
}

export const ImageFixed = ({ image, alt = "", className }) => {
  if (!image?.gatsbyImageData) {
    return null
  }

  return <GatsbyImage image={image.gatsbyImageData} alt={alt} className={className} />
}

export const createImageFluid = ({ url, layout = "fullWidth", width = 1, height = 0.5 }) => {
  const src = `${url}?w=1440&q=70`
  const srcSet = `${url}?w=480&h=320&q=70 480w,${url}?w=960&h=640&q=70 960w,${url}?w=1440&h=800&q=70 1440w`

  return {
    gatsbyImageData: {
      images: {
        fallback: { src, srcSet, sizes: "100vw" },
        layout,
        width,
        height
      }
    }
  }
}
