import tw, { styled } from "twin.macro"

export const PrimaryButton = styled.button`
  ${tw`font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:ring focus:outline-none transition duration-300`}

  a {
    ${tw`px-8 py-3 flex flex-grow`}
  }
`
export const SecondaryButton = styled.button`
  ${tw`font-bold rounded bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800 focus:ring focus:outline-none transition duration-300`}

  a {
    ${tw`px-8 py-3 flex flex-grow`}
  }
`
