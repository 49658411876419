import React from "react"
import "twin.macro"
import tw from "twin.macro"
//
import { ListItemsBlock } from "../../../storybook"
import { getAnchorLink } from "../../../utils/navigation"

const BlockListItems = ({ data: { id, titulo, items, tipo, simboloPrefixo }, isBlockPage = true }) => (
  <div css={[isBlockPage && tw`pt-8 lg:pt-10`]}>
    <ListItemsBlock
      heading={titulo}
      type={tipo}
      alignCenter={isBlockPage}
      symbol={simboloPrefixo}
      items={items?.map((item, idx) => {
        return {
          id: `item-${id}-${idx}`,
          value: item.startsWith("#") ? getAnchorLink(item) : <span>{item}</span>
        }
      })}
    />
  </div>
)

export default BlockListItems
