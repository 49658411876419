import React from "react"
import tw, { styled } from "twin.macro"
//
import { Container } from "../misc/Layouts"
import { SectionHeading } from "../misc/Headings"
import { SectionDescriptionContainer } from "../misc/Typography"

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8`

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Description = tw(SectionDescriptionContainer)`mx-auto text-center`

const ColsContainer = tw.div`mt-10 sm:mt-8 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto mb-12`
const Column = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`
const StyledColumn = styled(Column)`
  h4 {
    margin-bottom: 1rem;
  }
  p {
    line-height: 1.725rem;
  }
  a {
    ${tw`cursor-pointer mr-2 font-bold text-primary-500 border-b-2 border-transparent hocus:border-primary-500 hocus:text-primary-800 transition duration-300`};
  }
`

const MapContainerDiv = tw.div`w-full mt-10`
const MapContainer = styled(MapContainerDiv)`
  iframe {
    width: 100%;
  }
`

export const ContactsBlock = ({ heading, description, contacts1, contacts2, mapIframe }) => {
  return (
    <PrimaryBackgroundContainer>
      <HeadingContainer>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
      </HeadingContainer>
      {mapIframe && <MapContainer dangerouslySetInnerHTML={{ __html: mapIframe }} />}
      <ColsContainer>
        <StyledColumn>{contacts1}</StyledColumn>
        <StyledColumn>{contacts2}</StyledColumn>
      </ColsContainer>
    </PrimaryBackgroundContainer>
  )
}

export default ContactsBlock
