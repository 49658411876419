import React from "react"
//
import Metatags from "../seo"
import { Layout as StorybookLayout } from "../../storybook/layout"
import Footer from "../footer"
import Header from "../header"
import BackToTop from "../back-to-top"
import Search from "../search"

const darkHeros = ["ContentfulHeroImagemLateral"]

export const Layout = ({ tags, heroType, children }) => {
  const headerStyle = darkHeros.includes(heroType) ? "dark" : "light"
  const isBrowser = typeof window !== "undefined"

  const [searchActive, setSearchActive] = React.useState(false)

  const handleSearch = () => {
    setSearchActive(!searchActive)
  }

  return (
    <>
      <Metatags {...tags} />
      <StorybookLayout>
        <Search enabled={searchActive} onClose={() => setSearchActive(false)} />
        <Header headerStyle={headerStyle} searchActive={searchActive} handleSearch={handleSearch} />
        {children}
        <Footer />
        {isBrowser && !searchActive ? <BackToTop /> : null}
      </StorybookLayout>
    </>
  )
}

export default Layout
