import React from "react"
import { styled } from "twin.macro"
import { Link } from "gatsby"
//
import { PreviewContext, SiteDataContext } from "../../layout"
import LogoIdisLight from "./LogoIdisLight"
import LogoIdisDark from "./LogoIdisDark"

const logos = {
  idis: {
    light: LogoIdisLight,
    dark: LogoIdisDark
  }
}

const StyledLink = styled(Link)`
  img {
    width: auto;
  }
`

const CONTENTFUL_SITE_NAME = process.env.CONTENTFUL_SITE_NAME || ""

const HeaderLogo = ({ mode = "light" }) => {
  const site = CONTENTFUL_SITE_NAME.toLowerCase()
  const Logo = (logos[site] && logos[site][mode]) || null
  const preview = React.useContext(PreviewContext)
  const siteData = React.useContext(SiteDataContext)
  const { homepage } = siteData

  return (
    <StyledLink to={preview ? `/preview/?slug=${homepage.slug}` : "/"} aria-label="Navegar para a homepage">
      <Logo />
    </StyledLink>
  )
}

export default HeaderLogo
