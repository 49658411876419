/* eslint-disable no-useless-escape */
import React from "react"
import tw, { styled } from "twin.macro"
import { EmailNewsletterIcon as EmailNewsletterIconBase } from "../../images/Icons"
import { Container as ContainerBase } from "../misc/Layouts"
import { SectionHeading } from "../misc/Headings"
import { PrimaryButton } from "../misc/Buttons"

const Container = tw(ContainerBase)`bg-gradient-to-b from-primary-700 to-primary-500 -mx-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col lg:flex-row`
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-4/5 md:w-4/5 px-6`

const EmailNewsletterIcon = tw(EmailNewsletterIconBase)`w-12 h-12 text-gray-100`
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`
const Description = tw.p`text-gray-300 font-medium text-sm max-w-sm mt-2 sm:mt-1 text-center sm:text-left`

const Form = tw.form`text-sm max-w-sm sm:max-w-none md:text-center mx-auto`
const Input = tw.input`w-full sm:w-1/2 md:w-1/2 block sm:inline-block px-6 py-4 rounded bg-primary-200 tracking-wider font-bold border border-primary-600 focus:border-primary-300 focus:outline-none sm:rounded-r-none hover:bg-gray-100 transition duration-300`
const StyledInput = styled(Input)`
  &.error {
    ${tw`bg-red-500`};
  }
`
const Button = tw(
  PrimaryButton
)`w-full sm:w-1/4 md:w-1/4 mt-6 sm:mt-0 sm:rounded-l-none py-4 text-gray-100 hocus:text-gray-300 hover:bg-primary-800`

const Message = tw.h4`text-xl py-2 px-4`
const SuccessMessage = tw(Message)`bg-green-700 text-white`
const ErrorMessage = tw(Message)`bg-red-800 text-white`

const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const NewsletterBlock = ({ heading, description, labels, messages, onSubmit, formName = "newsletter" }) => {
  const [status, setStatus] = React.useState("")

  const handleSubmit = async (e) => {
    e.preventDefault()
    const email = e.target?.email?.value
    if (!email || !re.test(email) || (labels?.termsAndConditions && !e.target?.condicoes?.checked)) {
      setStatus("invalid")
      return
    }
    const data = { email, validation: e.target?.title?.validation }
    setStatus("submitting")
    const result = await onSubmit(data)
    setStatus(result?.status === "processed" ? result.status : "error")
  }

  return (
    <Container>
      <Content>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
            {(status === "" || status === "submitting" || status === "invalid") && (
              <Form name={formName} onSubmit={handleSubmit}>
                <div>
                  <input type="hidden" name="validation" />
                  <StyledInput
                    name="email"
                    type="email"
                    placeholder={labels?.email}
                    className={status === "invalid" ? "error" : ""}
                    aria-label={labels?.email}
                  />
                  <Button
                    aria-label="Pressione para subscrever"
                    disabled={status === "submitting" ? true : false}
                    type="submit"
                  >
                    {status === "submitting" ? "Processando" : labels?.submit}
                  </Button>
                </div>
                {labels?.termsAndConditions && (
                  <div tw="mt-3 md:ml-16 lg:ml-20 w-full sm:w-4/5 md:w-4/5 md:text-left">
                    <label tw="mt-4 sm:ml-1 md:ml-6 lg:ml-6 pl-1 flex">
                      <input name="condicoes" type="checkbox" tw="h-5 w-5" required />
                      <span tw="ml-2 text-white text-xs">{labels?.termsAndConditions}</span>
                    </label>
                  </div>
                )}
              </Form>
            )}
            {status === "processed" && <SuccessMessage>{messages?.success}</SuccessMessage>}
            {status === "error" && <ErrorMessage>{messages?.error}</ErrorMessage>}
          </FormColumn>
        </Row>
      </Content>
    </Container>
  )
}

export default NewsletterBlock
