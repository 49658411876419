import React from "react"
import { Helmet } from "react-helmet"
//

const Metatags = ({ siteName, title, description, type, image, canonical, robots }) => {
  return (
    <Helmet>
      {title && (
        <title key="title">
          {title} | {siteName || ""}
        </title>
      )}
      {description && <meta name="description" key="description" content={description} />}
      {type && <meta property="og:type" key="og_type" content={type} />}
      {title && <meta property="og:title" key="og_title" content={title} />}

      {description && <meta property="og:description" key="og_description" content={description} />}

      {canonical && <meta property="og:url" key="og_URL" content={canonical} />}

      {image && <meta property="og:image" key="og_image" content={image} />}

      {title && <meta property="og:site_name" key="og_site_name" content={siteName} />}

      <meta name="twitter:card" key="twitter_card" content="summary" />

      {description && <meta name="twitter:description" key="twitter_description" content={description} />}

      {image && <meta name="twitter:image:src" key="twitter_img" content={image} />}

      {robots && <meta name="robots" content={`${robots}`} />}
      {canonical && <link rel="canonical" key="canonical" href={canonical} />}
    </Helmet>
  )
}

export default Metatags
