import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, StaticQuery } from "gatsby"

const LogoIdisLight = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          logo: file(relativePath: { eq: "logo-idis-light.png" }) {
            childImageSharp {
              gatsbyImageData(width: 192, placeholder: NONE, layout: FIXED)
            }
          }
        }
      `}
      render={(data) => <GatsbyImage alt="logo" image={data?.logo?.childImageSharp?.gatsbyImageData} />}
    />
  )
}

export default LogoIdisLight
