import React from "react"
//
import GetStartedLight from "./GetStartedLight"
import GetStartedDark from "./GetStartedDark"

export const GetStarted = (props) => {
  if (props?.variant && props?.variant?.toLowerCase() === "light") {
    return <GetStartedLight {...props} />
  }

  return <GetStartedDark {...props} />
}

export default GetStarted
